import { FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { Link, useParams, useSearchParams } from "react-router-dom";
import MainLayout from "../../../components/Layout/Main";
import { Filtro } from "../components/filtro";
import { useEffect, useState } from "react";
import PostModel from "../../../interfaces/models/post.model";
import {
  getCategories,
  getPostsByCategory,
} from "../../../services/posts.service";
import toast from "react-hot-toast";
import { Loading } from "../../../components/Loading";
import NoItemsFound from "../../../components/no-items-found";
import IconShare from "../../../components/share";

export default function PostsByCategory() {
  const { categoryId } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<string>("Todas categorias");

  const emptyGuid = "00000000-0000-0000-0000-000000000000";

  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  const localStorageKey = "postsByCategory";

  const saveToLocalStorage = (data: PostModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const loadFromLocalStorage = (): PostModel[] => {
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : [];
  };

  async function fetchPosts(): Promise<void> {
    setLoading(true);

    getPostsByCategory(queryString, categoryId)
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorage();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorage(fetchedPosts);
          setPosts(fetchedPosts);
        } else {
          setPosts(storedPosts);
        }
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  async function fetchCategories(): Promise<void> {
    setLoading(true);

    getCategories()
      .then(({ data }) => {
        setSelectedCategory(
          data.find((c) => c.id === categoryId)?.title || "Desconhecida"
        );
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (categoryId === emptyGuid) {
      setSelectedCategory("Todas categorias");
    } else {
      fetchCategories();
    }

    const storedPosts = loadFromLocalStorage();
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
    }

    fetchPosts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, categoryId]);

  return (
    <MainLayout>
      <div className="container mw-100">
        <div className="row">
          <div className="col-md-3">
            <Filtro />
          </div>

          <div className="col-md-9">
            <div className="d-flex align-items-center flex-column flex-md-row mb-5">
              <h4 className="color-primary fw-semibold text-gilroy-semibold">
                {categoryId === emptyGuid ? "" : "Categoria"}
              </h4>
              <h4 className="text-black fw-semibold mx-2 text-gilroy-semibold">
                {!loading && selectedCategory}
              </h4>
            </div>

            <div className="row mb-4">
              {loading && <Loading center color="#062F53" />}

              {!loading && posts.length === 0 && (
                <p className="color-secondary text-gilroy-semibold h5 text-center text-md-start">
                  Em breve.
                </p>
              )}

              {!loading && posts.length === 0 && <NoItemsFound />}

              {!loading &&
                posts.map((post) => (
                  <div
                    key={post.id}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 col-sm-6 mt-30 position-relative"
                  >
                    <div className="mb-0">
                      <div className="badge position-absolute text-center bg-white d-flex align-items-center">
                        {post.preco > 0 && (
                          <div className="text-gilroy-semibold">
                            <span className="badge-category fw-bold ms-2 me-1">
                              R$
                            </span>
                            <span className="badge-category fw-bold me-2">
                              {post.preco}
                            </span>
                          </div>
                        )}
                        <span className="badge-category-description fw-bold">
                          {post.pagamento}
                        </span>
                      </div>
                      <Link to={`/posts/${post.id}`}>
                        <img
                          src={post.poster}
                          className="img-fluid img-poster cursor-pointer"
                          alt="Imagem"
                        />
                      </Link>
                      <img
                        src={`/images/categories/${post.category?.systemKey}_up.svg`}
                        alt={post.category?.title}
                        className="img-fluid"
                        style={{ marginTop: "-20px" }}
                      />
                      <div
                        className="d-flex icons-category justify-content-end mb-2"
                        style={{ marginRight: "-25px" }}
                      >
                        <a
                          href={`https://api.whatsapp.com/send?text=Veja o item ${
                            post.title
                          } - ${
                            post.preco > 0
                              ? `R$ ${post.preco} ${post.pagamento}`
                              : post.pagamento
                          }! ${
                            window.location.href
                          } Baixe o SuperTrip para não perder nenhuma item`}
                          target="_blank"
                          rel="noreferrer"
                          className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                        >
                          <FaWhatsapp className="text-white" />
                        </a>
                        <IconShare post={post} />
                      </div>
                      <div className="text-card">
                        <Link
                          to={`/ofertas/${post.id}`}
                          className="text-black fw-semibold text-md-start"
                          style={{ marginBottom: "-2px" }}
                        >
                          {post.title}
                        </Link>
                        <div className="d-flex align-items-center mb-5">
                          <IoLocationSharp className="icon-color" />
                          <p
                            className="mb-0 mx-1 text-dark-emphasis fw-semibold text-gilroy-semibold"
                            style={{ fontSize: "13px" }}
                          >
                            {post.cidade}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
