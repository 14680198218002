import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getUserProfile } from "../../services/user.service";

export default function HeaderAdmin() {
  const navigate = useNavigate();

  const [emailProfile, setEmailProfile] = useState<string>("exemplo@email.com");

  async function fetchEmailProfile(): Promise<void> {
    getUserProfile()
      .then(({ data }) => {
        setEmailProfile(data?.email ?? "exemplo@email.com");
      })
      .catch((err) => {
        toast.error(
          err.response.data[0] ?? "Erro ao carregar e-mail do usuário"
        );
      });
  }

  function logout(): void {
    localStorage.clear();
    navigate("/");
    toast.success("Deslogado com sucesso!");
  }

  useEffect(() => {
    fetchEmailProfile();
  }, []);

  return (
    <nav
      className="navbar fixed-top navbar-dark"
      style={{ background: "#300B99" }}
    >
      <div className="container-fluid mx-4">
        <a href="/" className="navbar-brand mx">
          <img src="/images/logo/brand-blue.png" height="30" loading="lazy" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <Link className="nav-link" to="/admin">
              Home
            </Link>
            <NavLink className="nav-link" to="/admin/posts">
              Ofertas
            </NavLink>
            <NavLink className="nav-link" to="/admin/users">
              Usuários
            </NavLink>
            <NavLink className="nav-link" to="/admin/parameters">
              Parâmetros
            </NavLink>
            <NavLink className="nav-link" to="/admin/push-notification">
              Enviar push genérico
            </NavLink>
            <NavLink
              className="nav-link"
              id="hello"
              title="Manage"
              to="/admin/account"
            >
              Hello {emailProfile}
            </NavLink>
            <button className="d-flex nav-link" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}
