import { useForm } from "react-hook-form";
import { FaArrowRight } from "react-icons/fa";
import Input from "../../../components/Form/Input";
import LoginArgs from "../../../interfaces/args/login.args";
import { login } from "../../../services/auth.service";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Loading } from "../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { getAdmin } from "../../../services/admin.service";

interface FormValues {
  email: string;
  password: string;
}

export const Form = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      email: "",
      password: "",
    },
  });

  const searchParams = new URLSearchParams(window.location.search);
  const postId = searchParams.get("postId");

  async function handleLogin(data: FormValues): Promise<void> {
    let args: LoginArgs = {
      email: data.email,
      password: data.password,
    };

    setLoading(true);

    login(args)
      .then(({ data }) => {
        localStorage.setItem("auth", JSON.stringify(data));
        const redirectPath = postId ? `/posts/${postId}` : "/";
        navigate(redirectPath);
        toast.success("Bem-vindo!");

        getAdmin();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <form onSubmit={handleSubmit(handleLogin)} className="w-100 mb-4">
      <div className="row g-3">
        <div className="col-md-6">
          <Input
            control={control}
            errors={errors}
            name="email"
            validation={{
              required: "Campo e-mail é obrigatório",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Endereço de e-mail é inválido",
              },
            }}
            type="email"
            placeholder="Digite seu e-mail aqui"
          />
        </div>

        <div className="col-md-6">
          <Input
            type="password"
            control={control}
            errors={errors}
            name="password"
            validation={{ required: "Campo senha é obrigatório" }}
            placeholder="Digite sua senha aqui"
          />
        </div>
      </div>

      <button
        type={loading ? "button" : "submit"}
        className="btn btn-yellow w-100 mt-3 border-0"
        disabled={loading}
      >
        {loading ? <Loading /> : <span className="align-middle">Entrar</span>}
        <FaArrowRight />
      </button>
    </form>
  );
};
