import { Form } from "./components/form";

export default function PostCreate() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h3 className="text-center m-0">Cadastrar</h3>

      <Form />
    </div>
  );
}
