import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/Main";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { useState } from "react";
import UserModel from "../../interfaces/models/user.model";
import { deleteUser } from "../../services/user.service";
import toast from "react-hot-toast";
import Modal from "../../components/Modal";
import { Loading } from "../../components/Loading";

export default function About() {
  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [perfil, setPerfil] = useState<UserModel>({} as UserModel);

  function handleModalDeleteOpen(perfil: UserModel) {
    setPerfil(perfil);
    setIsModalDeleteOpen(true);
  }

  function handleModalDeleteClose() {
    setIsModalDeleteOpen(false);
    setPerfil({} as UserModel);
  }

  function logout(): void {
    localStorage.clear();
    navigate("/");
  }

  async function handleDeletePerfil(): Promise<void> {
    setLoading(true);

    deleteUser()
      .then(() => {
        toast.success("Perfil deletado com sucesso!");
        logout();
      })
      .catch((err) => {
        toast.error(err.response.data[0] ?? "Erro ao deletar perfil");
      })
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout background="transparent">
      <div className="container-xl text-gilroy-semibold">
        <p className="title-about mb-4">Sobre o SuperTrip</p>
        <p className="description-about">
          Olá, sejam muito bem-vindos ao SuperTrip.me! 🤑
        </p>
        <p className="description-about">
          Basicamente, somos uma plataforma que te ajuda a economizar com estilo
          e facilidade.
        </p>
        <p className="description-about">
          Uma blackfriday eterna, já que te ajudamos a economizar 24 horas por
          dia, sete dias por semana e não apenas na última sexta-feira do mês de
          novembro rsrs.
        </p>
        <p className="description-about">
          Para a gente, todo dia é dia do consumidor e dia de acessar ofertas
          que vão te deixar mais próximo de adquirir aquele produto que você
          tanto quer! 👀
        </p>
        <p className="description-about">Quer ver como? Vem comigo ;)</p>
        <p className="description-about">Descontos em vários nichos</p>
        <p className="description-about">
          Por meio do site www.supertrip.me e do app te damos acesso direto às
          melhores promoções das melhores categorias de produtos e serviços,
          como alimentação, beleza, bebês, brinquedos, esportes, cozinha,
          eletrônicos, livros, petshop, vestuário e inúmeras outras.
        </p>
        <p className="description-about">Autonomia e coparticipação</p>
        <p className="description-about">
          Outra coisa importante que fazemos questão que você saiba é que o
          Super Trip é uma plataforma autônoma e transparente, sem ligação com
          redes varejistas, e baseada no conceito de coparticipação.
        </p>
        <p className="description-about">
          Ou seja, nossos próprios usuários têm a capacidade de registrar as
          ofertas que, por ventura, encontrarem, com bom custo-benefício, sendo
          necessário para isso apenas um simples cadastro e a aprovação do nosso
          sistema de mediação. 🤙
        </p>
        <p className="description-about">Simplicidade de acesso</p>
        <p className="description-about">
          Lembramos que o cadastro não é obrigatório para acessar as vantagens
          da plataforma.
        </p>
        <p className="description-about">
          Entretanto, além de poder registrar promoções e descontos que são do
          seu interesse (e que com certeza poderão interessar a outras pessoas),
          o cadastro também te dá direito a receber notificações personalizadas
          diretamente no seu celular. 👨‍💻
        </p>
        <p className="description-about">Personalização de ofertas</p>
        <p className="description-about">
          Isso mesmo. No SuperTrip.me você pode escolher a categoria que te
          interessa mais e optar ser avisado quando sugerir aquele desconto
          especial que você tanto espera, evitando chamar sua atenção para
          coisas que não sejam realmente o que você quer e precisa!
        </p>
        <p className="description-about">
          Assim vai ser difícil você perder o novo smartphone do momento ou
          aquela baixa de preço que você aguarda há tanto tempo para comprar o
          brinquedo que vai entreter seu pet! 🙅‍♂️
        </p>
        <p className="description-about">Viu como é fácil?</p>
        <p className="description-about">
          Aproveite para nos seguir no Instagram e se tiver qualquer dificuldade
          não deixe de entrar em contato conosco pelo contato@supertrip.me
        </p>
        <p className="description-about">Espero você! 😻</p>

        {isAuth ? (
          <button
            className="btn btn-danger mt-2 rounded-pill"
            onClick={() => handleModalDeleteOpen(perfil)}
          >
            Excluir conta
          </button>
        ) : null}

        <div className="version mt-4">Versão 1.0.0.0 2020</div>
      </div>

      <Modal close={handleModalDeleteClose} isOpen={isModalDeleteOpen}>
        <div className="row d-flex justify-content-center align-items-center text-center">
          <p className="h5">Deseja excluir PERMANENTEMENTE a sua conta?</p>
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-evenly align-items-center mb-3 mt-3">
            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={handleModalDeleteClose}
            >
              Cancelar
            </button>

            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100"
              style={{ width: 175 }}
              onClick={handleDeletePerfil}
            >
              {loading ? (
                <Loading />
              ) : (
                <span className="align-middle">Excluir</span>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
}
