import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../components/Form/Input";
import PostArgs from "../../../../interfaces/args/post.args";
import CategoryModel from "../../../../interfaces/models/category.model";
import {
  getCategories,
  postPosts,
  uploadImage,
} from "../../../../services/posts.service";
import toast from "react-hot-toast";
import { Loading } from "../../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../../services/user.service";

interface FormValues {
  title: string;
  category: string;
  description: string;
  price: string;
  paymentTerms: string;
  shop: string;
  link: string;
}

export const Form = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [imagePost, setImagePost] = useState<string>();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: "",
      category: "",
      description: "",
      price: "",
      paymentTerms: "",
      shop: "",
      link: "",
    },
  });

  function handleUploadClick() {
    inputFileRef.current?.click();
  }

  function uploadImagePost(event: React.ChangeEvent<HTMLInputElement>) {
    setLoading(true);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const formData = new FormData();
        formData.append("file", file);

        uploadImage(formData)
          .then((res) => {
            setImagePost(res.data.url);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data[0] ??
                "Erro ao enviar imagem. Tente novamente."
            );
          })
          .finally(() => {
            setLoading(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }

  async function getCategoriesPost() {
    setLoading(true);

    getCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function handleCreatePost(data: FormValues): Promise<void> {
    if (!imagePost) {
      toast.error("Selecione uma imagem para o post!");
      return;
    }

    let category = categories.find((c) => c.id === data.category);

    let args: PostArgs = {
      approved: true,
      date: new Date().toISOString(),
      featured: true,
      rememberMe: true,
      rememberMeCount: 0,
      title: data.title,
      categoryId: data.category,
      category: category ? category : null,
      description: data.description,
      poster: imagePost,
      preco: parseFloat(data.price.replace(",", ".")),
      pagamento: data.paymentTerms,
      cidade: data.shop,
      link: data.link,
      platforms: null,
    };

    const { data: user } = await getUserProfile();

    setLoading(true);

    postPosts(args)
      .then(() => {
        if (window.location.pathname === "/admin/posts/new") {
          navigate("/admin/posts");
        } else {
          navigate("/");
        }
        toast.success("Post cadastrado!");
      })
      .catch((err) => {
        if (user.blocked) {
          toast.error("Você não tem permissão para realizar essa função!");
        }
        toast.error(err?.response?.data[0] ?? "Erro ao cadastrar post");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getCategoriesPost();
  }, []);

  return (
    <form
      className="form-cadastro-post"
      onSubmit={handleSubmit(handleCreatePost)}
    >
      <label className="capa cursor-pointer">
        {/* Input file hidden */}
        <input
          ref={inputFileRef}
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          multiple={false}
          onChange={uploadImagePost}
          onClick={handleUploadClick}
        />

        <img src={imagePost || `/images/no-picture.png`} alt="Capa do post" />
        <span>Imagem de capa</span>
      </label>

      <div className="divider mt-5"></div>

      <div className="row g-3 px-3 px-md-5 py-5">
        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="title" className="cadastro-post-label mb-2">
              Título
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="title"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.title && (
            <small className="text-danger">
              {errors.title?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon arrow-right">
            <label htmlFor="category" className="mb-2 cadastro-post-label">
              Categoria
            </label>
            <select
              {...register("category", { required: "Campo obrigatório" })}
              className={clsx(
                "cadastro-post-input",
                errors.category && "border border-danger text-danger"
              )}
            >
              <option disabled value="">
                Selecione uma categoria
              </option>
              {categories.map((category) => (
                <option
                  disabled={!category.id}
                  key={category.id}
                  value={category.id}
                >
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          {errors.category && (
            <small className="text-danger">
              {errors.category?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon icon-textarea edit">
            <label htmlFor="description" className="mb-2 cadastro-post-label">
              Descrição
            </label>
            <textarea
              {...register("description", { required: "Campo obrigatório" })}
              rows={6}
              className={clsx(
                "cadastro-post-textarea",
                errors.description && "border border-danger text-danger"
              )}
              placeholder="Insira sua descrição mais detalhada possível."
            ></textarea>
            {errors.description && (
              <small className="text-danger">
                {errors.description?.message?.toString()}
              </small>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="price" className="cadastro-post-label mb-2">
              Preço
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="price"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.price && (
            <small className="text-danger">
              {errors.price?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="paymentTerms" className="cadastro-post-label mb-2">
              Condição de pagamento
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="paymentTerms"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
              placeholder="à vista, parcela, em até 12x, etc"
            />
          </div>
          {errors.paymentTerms && (
            <small className="text-danger">
              {errors.paymentTerms?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Mensageiro
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="shop"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.shop && (
            <small className="text-danger">
              {errors.shop?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="link" className="cadastro-post-label mb-2">
              Link
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="link"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.link && (
            <small className="text-danger">
              {errors.link?.message?.toString()}
            </small>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center footer-cadastro-post">
        <button
          type={loading ? "button" : "submit"}
          className="btn btn-cadastro-post border-0"
          disabled={loading}
        >
          {loading ? (
            <Loading />
          ) : (
            <span className="align-middle text-gilroy-semibold">Cadastrar</span>
          )}
        </button>
      </div>
    </form>
  );
};
