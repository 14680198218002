import { useEffect, useState } from "react";
import { FaBars, FaHome, FaSearch } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { getUserProfile } from "../../services/user.service";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import CategoryModel from "../../interfaces/models/category.model";
import { getCategories } from "../../services/posts.service";
import { Loading } from "../Loading";

type SearchProps = {
  search: string;
};

export default function Header() {
  const navigate = useNavigate();

  const isAuth: boolean = useCheckAuth();

  const [imageProfile, setImageProfile] = useState<string>("/images/anon.png");
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  async function fetchImageProfile(): Promise<void> {
    const userProfileImage = localStorage.getItem("userProfileImage");

    if (isAuth && userProfileImage) {
      setImageProfile(userProfileImage);
      return;
    } else if (isAuth && !userProfileImage) {
      getUserProfile()
        .then(({ data }) => {
          setImageProfile(data?.profilePicture ?? "/images/anon.png");
        })
        .catch((err) => {
          toast.error(
            err?.response?.data[0] ?? "Erro ao carregar imagem do usuário"
          );
        });
    }
  }

  useEffect(() => {
    fetchImageProfile();
  }, []);

  function logout(): void {
    localStorage.clear();
    navigate("/");
    toast.success("Deslogado com sucesso!");
  }

  const { register, handleSubmit } = useForm<SearchProps>({
    values: {
      search: queryString ?? "",
    },
  });

  function handleFilter(data: SearchProps) {
    navigate(`/?query=${data.search}`);
  }

  async function fetchFilter(): Promise<void> {
    setLoading(true);

    getCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchFilter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className="navbar navbar-expand-sm navbar-light">
      <div className="container-xl d-flex justify-content-between align-items-center p-2">
        <button
          className="navbar-toggler d-flex d-sm-none align-items-center justify-content-center"
          style={{ borderColor: "#fff", color: "#fff" }}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <FaBars />
        </button>

        <div className="mb-0 mb-sm-1">
          <Link to="/" className="navbar-brand mx-auto">
            <img
              src={`/images/logo/brand.png`}
              alt="Logo"
              style={{ maxHeight: 40 }}
              className="logo-image d-flex align-items-center justify-content-center"
            />
          </Link>
        </div>

        <div className="d-flex d-sm-none align-items-center text-white">
          <div className="nav-item dropdown">
            <button
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={imageProfile}
                alt="perfil"
                className="rounded-circle cursor-pointer image-profile border"
              />
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end pt-0 pt-lg-2"
              aria-labelledby="navbarDropdown"
            >
              {isAuth && (
                <Link
                  to="/posts/new"
                  className="mb-2 px-2 d-flex d-lg-none btn-oferta text-decoration-none align-items-center"
                  style={{
                    borderRadius: "0.375rem",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <span className="text-gilroy-semibold mx-2">
                    Adicionar item
                  </span>
                </Link>
              )}
              <li>
                {isAuth ? (
                  <Link
                    to="/profile"
                    className="dropdown-item text-gilroy-semibold"
                  >
                    Perfil
                  </Link>
                ) : null}
              </li>
              <li>
                <Link
                  to="/about"
                  className="dropdown-item text-gilroy-semibold"
                >
                  Sobre
                </Link>
              </li>
              <li>
                {isAuth ? (
                  <button
                    onClick={logout}
                    className="dropdown-item cursor-pointer text-gilroy-semibold"
                  >
                    Sair
                  </button>
                ) : (
                  <Link
                    to="/login"
                    className="dropdown-item text-gilroy-semibold"
                  >
                    Entrar
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>

        <div
          className="collapse navbar-collapse collapse-style collapse-color mt-3 mt-sm-0"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-sm-0">
            <li className="nav-item d-flex align-items-center justify-content-center">
              <Link
                to="/"
                className="d-none d-md-flex align-items-center ms-3 me-4 text-white text-gilroy-semibold"
                style={{ fontSize: 16 }}
              >
                <FaHome className="me-3" />
                Início
              </Link>
            </li>
            <li className="nav-item mx-3 mt-3 mt-sm-0 mb-3 mb-sm-0">
              <form className="d-flex" onSubmit={handleSubmit(handleFilter)}>
                <input
                  className="search-input form-control me-2 border-0 text-gilroy-semibold"
                  type="search"
                  placeholder="Pesquisar"
                  aria-label="Search"
                  {...register("search")}
                />
                <button
                  className="btn input-group-text border-0 btn-search"
                  type="submit"
                >
                  <FaSearch style={{ color: "#FCAB2A" }} />
                </button>
              </form>
            </li>
            <li className="nav-item">
              {isAuth && (
                <div>
                  <Link
                    to="/posts/new"
                    className="d-none d-lg-flex btn-oferta text-decoration-none align-items-center ms-3 me-3"
                  >
                    <span className="m-auto text-gilroy-semibold">
                      Adicionar item
                    </span>
                  </Link>
                </div>
              )}
            </li>

            <div className="nav-item d-flex d-sm-none categories-list align-items-center justify-content-center">
              {loading && <Loading center />}

              {!loading && (
                <div className="row">
                  <div className="col-6 my-1">
                    <Link
                      className="menu-category-item mb-2"
                      to={`/posts/category/00000000-0000-0000-0000-000000000000`}
                    >
                      <img
                        src={
                          window.location.pathname === "/" ||
                          window.location.pathname ===
                            "/posts/category/00000000-0000-0000-0000-000000000000"
                            ? "/images/categories/todascategorias_selected.svg"
                            : "/images/categories/todascategorias.svg"
                        }
                        alt="Todas"
                      />
                    </Link>
                  </div>

                  {categories.map((c) => (
                    <div key={c.id} className="col-6 my-2">
                      <Link
                        to={`/posts/category/${c.id}`}
                        className="text-gilroy-semibold text-white"
                      >
                        <img
                          src={
                            window.location.pathname.includes(
                              "/category/" + c.id
                            )
                              ? `/images/categories/${c.systemKey}_selected.svg`
                              : `/images/categories/${c.systemKey}.svg`
                          }
                          alt={c.title}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </ul>
        </div>

        <div className="d-none d-sm-flex align-items-center text-white">
          <div className="nav-item dropdown">
            <button
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={imageProfile}
                alt="perfil"
                className="rounded-circle cursor-pointer image-profile border"
              />
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end pt-0 pt-lg-2"
              aria-labelledby="navbarDropdown"
            >
              {isAuth && (
                <Link
                  to="/posts/new"
                  className="mb-2 px-2 d-flex d-lg-none btn-oferta text-decoration-none align-items-center"
                  style={{
                    borderRadius: "0.375rem",
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <span className="text-gilroy-semibold mx-2">
                    Adicionar item
                  </span>
                </Link>
              )}
              <li>
                {isAuth ? (
                  <Link
                    to="/profile"
                    className="dropdown-item text-gilroy-semibold"
                  >
                    Perfil
                  </Link>
                ) : null}
              </li>
              <li>
                <Link
                  to="/about"
                  className="dropdown-item text-gilroy-semibold"
                >
                  Sobre
                </Link>
              </li>
              <li>
                {isAuth ? (
                  <button
                    onClick={logout}
                    className="dropdown-item cursor-pointer text-gilroy-semibold"
                  >
                    Sair
                  </button>
                ) : (
                  <Link
                    to="/login"
                    className="dropdown-item text-gilroy-semibold"
                  >
                    Entrar
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
